@charset "UTF-8";
/* Copied from Bootstrap 5 */
table {
  border-collapse: collapse;
  /* Row grouping */
}
table thead {
  border-bottom: 2px solid #BCBCBC;
}
table tfoot {
  border-top: 2px solid #BCBCBC;
}
table .btn-group {
  white-space: nowrap;
}
table .btn-group .btn {
  display: inline-block;
  float: none;
}
table.indexes td {
  vertical-align: middle;
}
table > caption span.actions {
  font-size: 66%;
  font-weight: normal;
  margin: 0 0.5em 0 0;
}
table > caption button.dt-button {
  background: transparent none;
  border: 0;
  color: #595959;
  font-size: 60%;
  margin: 0 0.5em;
}
table > caption button.dt-button:hover:not(.disabled):active:not(.disabled) {
  background: transparent none;
  border: 0;
  box-shadow: none;
  color: #000;
}
table > caption button.dt-button:hover:not(.disabled) {
  background: transparent none;
  border: 0;
  color: #000;
}
table > caption div.dt-button-collection {
  font-size: 70%;
}
table.invis {
  border: 0;
}
table.invis tr,
table.invis td {
  border: 0;
}
table + table {
  margin-top: 1em;
}
table.dataTable {
  border-collapse: collapse;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
table.dataTable.fixedHeader-floating {
  margin-top: 0;
}
table.dataTable tfoot td,
table.dataTable tfoot th {
  padding: 0.5em;
}
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #BCBCBC;
  border-left: 1px solid #BCBCBC;
  padding: 0.5em;
}
table.dataTable thead th:first-child,
table.dataTable thead td:first-child {
  border-left: 0;
}
table.dataTable thead th:last-child,
table.dataTable thead td:last-child {
  border-right: 0;
}
table.dataTable thead tr.columnFilter th {
  padding: 0;
}
table.dataTable thead .sorting_asc {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi4zODRtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMiAyLjM4NDAzODkiPjxnPjxwYXRoIGZpbGw9IiMwMDZhY2MiIGQ9Ik00LjA1IDEuNzRoLTIuMUwzIC42NHoiLz48L2c+PC9zdmc+") no-repeat scroll right center #FFF;
  padding-right: 19px;
}
table.dataTable thead .sorting_asc.fixed_sort {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iOS4wMSIgdmlld0JveD0iMCAwIDYgMi4zODQiPjxwYXRoIGZpbGw9IiM5NDk0OTQiIGQ9Ik00LjA1IDEuNzRoLTIuMUwzIC42NHoiLz48L3N2Zz4=") no-repeat scroll right center #FFF;
  cursor: default;
}
table.dataTable thead .sorting_desc {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi4zODRtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMiAyLjM4NDAzODkiPjxnPjxwYXRoIGZpbGw9IiMwMDZhY2MiIGQ9Ik00LjA1LjY0aC0yLjFMMyAxLjc0eiIvPjwvZz48L3N2Zz4=") no-repeat scroll right center #FFF;
  padding-right: 19px;
}
table.dataTable thead .sorting_desc.fixed_sort {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iOS4wMSIgdmlld0JveD0iMCAwIDYgMi4zODQiPjxwYXRoIGZpbGw9IiM5NDk0OTQiIGQ9Ik00LjA1LjY0aC0yLjFMMyAxLjc0eiIvPjwvc3ZnPg==") no-repeat scroll right center #FFF;
  cursor: default;
}
table.dataTable thead .sorting:not(.sorting_asc):not(.sorting_desc) {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi41NTFtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMyAyLjU1MDY2MTQiPjxwYXRoIGZpbGw9IiNhY2FjYWMiIGQ9Ik00LjA1IDEuNDVoLTIuMUwzIDIuNTV6Ii8+PHBhdGggZmlsbD0iI2FjYWNhYyIgZD0iTTQuMDUgMS4xaC0yLjFMMyAweiIvPjwvc3ZnPg==") no-repeat scroll right center #FFF;
  padding-right: 19px;
}
table.dataTable tbody td {
  padding: 0.5em;
}
table.group tr.odd td {
  background-color: #F3F3F3;
  border: 1px solid #BCBCBC;
  border-right: 1px solid #BCBCBC;
}
table.group tr.even td {
  background-color: #FFF;
  border-right: 1px solid #BCBCBC;
}
table.group td.group {
  background-color: #D1CFD0;
  border-bottom: 2px solid #A19B9E;
  border-top: 2px solid #A19B9E;
}

td,
th {
  border-bottom: 1px solid #BCBCBC;
  border-left: 1px solid #BCBCBC;
  padding: 0.2em 0.9em;
}
td:first-child,
th:first-child {
  border-left: none;
}
td:last-child,
th:last-child {
  border-right: none;
}

td {
  background-color: #FFFFFF;
  vertical-align: top;
  /* Row grouping */
}
td.actions {
  white-space: nowrap;
}
td.data {
  font-family: "Courier New", Courier, monospace;
  text-align: right;
}
td.total {
  text-align: right;
}
td input.approve {
  background-color: #FFC;
}
td.group {
  background-color: #D1CFD0;
  border-bottom: 2px solid #A19B9E;
  border-top: 2px solid #A19B9E;
}

th {
  background-color: #FFF;
  font-weight: bold;
  text-align: center;
}
th[scope=row] {
  text-align: right;
}
th.data {
  font-family: "Courier New", Courier, monospace;
  text-align: right;
}

tr.clickable {
  cursor: pointer;
}
tr.expired td {
  color: #999999;
}
tr.highlight td {
  background-color: #F6F6F6;
  border-color: #BCBCBC;
}
tr.highlight th[scope=row] {
  background-color: #DDDDDD;
  border-color: #BCBCBC;
}
tr.highlight table.invis td {
  border: 0;
}
tr.odd.onissue td {
  background-color: #FFFFE1;
}
tr.ok td {
  background-color: #FFFFCC;
}
tr.ok:nth-child(odd) td {
  background-color: #FFFFCC;
}
tr.ok:nth-child(even) td {
  background-color: #FFFFCC;
}
tr.onissue td {
  background-color: #FFFFCC;
}
tr.reserved td {
  background-color: #EEFFD4;
}
tr.transfered td {
  background-color: #E8F0F6;
}
tr.warn td {
  background-color: #FF9090;
}
tr.warn:nth-child(odd) td {
  background-color: #FF9090;
}
tr.default td {
  font-weight: bold;
}
tr.debug td {
  color: #CC0000;
  font-style: italic;
  font-weight: bold;
}

.selections-table tr:hover td {
  background-color: #FFFF99 !important;
}

tfoot td {
  background-color: #f3f4f4;
  font-weight: bold;
  vertical-align: middle;
}

caption {
  color: #000;
  font-size: 133.9%;
  font-weight: bold;
  margin: 0.3em 0;
}

.highlighted-row,
.highlighted-row td {
  background-color: #FFD000 !important;
}

.warned-row,
.warned-row td {
  background-color: #FF9000 !important;
}

.high-warned-row,
.high-warned-row td {
  background-color: #FF0000 !important;
}

tbody tr:nth-child(odd):not(.dtrg-group, .active, .ok) td:not(.bg-danger, .bg-warning, .bg-info, .bg-success, .bg-primary) {
  background-color: #f3f4f4;
}

.dataTables_wrapper .dataTables_processing {
  background-color: #F4F8F9;
  border: 2px solid #538200;
  border-radius: 3px;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
  color: #333;
  height: unset;
  left: 50%;
  margin-left: -125px;
  margin-top: -15px;
  padding: 1.5em;
  position: fixed;
  text-align: center;
  top: 50%;
  width: auto;
}
.dataTables_wrapper .dataTables_info {
  float: none;
  line-height: 1.9em;
  padding-right: 1em;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_length {
  display: none;
  float: none;
  line-height: 1.5em;
  padding-right: 1em;
}
.dataTables_wrapper .dataTables_filter {
  float: none;
  padding-right: 1em;
  white-space: nowrap;
}
.dataTables_wrapper .dt-buttons {
  display: flex;
}
.dataTables_wrapper .dataTables_paginate {
  float: none;
  line-height: 1.5em;
  padding: 0;
  padding-right: 1em;
}
.dataTables_wrapper .dataTables_paginate span .paginate_button,
.dataTables_wrapper .dataTables_paginate span .ellipsis {
  display: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border-radius: 4px;
  color: #C00832 !important;
  padding-bottom: 0;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: transparent none;
  border: 1px solid transparent;
  box-shadow: none;
  color: #a8072c !important;
  text-decoration: underline !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #ffb4e3 none;
  border: 0;
  color: #666;
  font-weight: bold;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current:active {
  background: #ffdcf2 none;
  border: 0;
  box-shadow: none;
  color: #666;
  cursor: default;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.first::before, .dataTables_wrapper .dataTables_paginate .paginate_button.first::after, .dataTables_wrapper .dataTables_paginate .paginate_button.previous::before, .dataTables_wrapper .dataTables_paginate .paginate_button.previous::after, .dataTables_wrapper .dataTables_paginate .paginate_button.next::before, .dataTables_wrapper .dataTables_paginate .paginate_button.next::after, .dataTables_wrapper .dataTables_paginate .paginate_button.last::before, .dataTables_wrapper .dataTables_paginate .paginate_button.last::after {
  display: inline-block;
  font-family: FontAwesome;
  width: 1em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.first {
  display: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.first::before {
  content: "\f100";
  padding-right: 0.5em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous::before {
  content: "\f104";
  padding-right: 0.5em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.next::after {
  content: "\f105";
  padding-left: 0.5em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.last {
  display: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.last::after {
  content: "\f101";
  padding-left: 0.5em;
}

.dt-info {
  margin-top: 0.5em;
}

.dt-info + .pager {
  border-top: 1px solid #BCBCBC;
  margin-top: 0.3em;
}

.pager {
  display: flex;
  flex-wrap: wrap;
  font-size: 90%;
}
.pager.top {
  padding-top: 1em;
}
.pager.bottom {
  border-bottom: 1px solid #BCBCBC;
  padding-bottom: 1em;
}
.pager div.dt-buttons {
  display: flex;
  flex-grow: 1;
}
.pager div.dt-buttons button:first-of-type {
  margin-right: auto;
}
.pager input[type=search] {
  border: 1px solid #CCC;
}
.pager:empty {
  border: 0;
  padding: 0;
}

/* /.pager */
button.dt-button,
div.dt-button,
a.dt-button {
  background: transparent none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #000;
  font-size: 1em;
  line-height: 1.7em;
  margin: 0 3px 0;
  padding: 0 0.5em;
}
button.dt-button::before,
div.dt-button::before,
a.dt-button::before {
  content: "";
}
button.dt-button.disabled,
div.dt-button.disabled,
a.dt-button.disabled {
  background: transparent none;
  border: 1px solid transparent;
  color: #999;
  cursor: default;
}
button.dt-button.disabled:focus,
div.dt-button.disabled:focus,
a.dt-button.disabled:focus {
  border: 1px solid #ADADAD;
}
button.dt-button.disabled a,
button.dt-button.disabled span,
div.dt-button.disabled a,
div.dt-button.disabled span,
a.dt-button.disabled a,
a.dt-button.disabled span {
  background: transparent none;
}
button.dt-button:active:not(.disabled), button.dt-button:focus:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button:focus:not(.disabled) {
  background: transparent none;
  border: 1px solid #ADADAD;
  box-shadow: none;
  text-shadow: none;
}
button.dt-button:active:not(.disabled):not(.disabled):hover:not(.disabled), button.dt-button:focus:not(.disabled):not(.disabled):hover:not(.disabled),
div.dt-button:active:not(.disabled):not(.disabled):hover:not(.disabled),
div.dt-button:focus:not(.disabled):not(.disabled):hover:not(.disabled),
a.dt-button:active:not(.disabled):not(.disabled):hover:not(.disabled),
a.dt-button:focus:not(.disabled):not(.disabled):hover:not(.disabled) {
  background: #EEE none;
  border: 1px solid transparent;
  box-shadow: none;
}
button.dt-button:hover,
div.dt-button:hover,
a.dt-button:hover {
  background: transparent none;
  border: 1px solid transparent;
}
button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  background: #EEE none;
}
button.dt-button.active:not(.disabled):hover:not(.disabled),
div.dt-button.active:not(.disabled):hover:not(.disabled),
a.dt-button.active:not(.disabled):hover:not(.disabled) {
  background: #EEE none;
  border: 1px solid transparent;
}

.toptabs .pager {
  margin: 0;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
}
.toptabs .pager.bottom {
  border-bottom: 0;
  padding-bottom: 0;
}

.filter_column > input[type=text] {
  border: 0;
  color: #999;
  font-size: 85%;
  width: 100%;
}

div.dt-button-collection {
  width: auto;
}
div.dt-button-collection button.dt-button {
  border-radius: 2px;
  text-align: left;
}
div.dt-button-collection button.dt-button.buttons-collection {
  border: 0;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility {
  background: #FFF none;
  border: 1px solid #EEE;
  box-shadow: none;
  font-size: 1em;
  margin: 0 0 4px 0;
  padding: 0.3em 0.7em;
  text-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility::before {
  color: #CC0000;
  content: "\f00d";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility:hover {
  background: #FFC none;
  border: 1px solid #999;
  box-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility:hover::before {
  color: #538200;
  content: "\f00c";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility:active:not(.disabled):hover:not(.disabled) {
  background: transparent none;
  box-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility.active {
  background: #E6F0F2 none;
  border-color: #999;
  box-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility.active:hover {
  background: #FFC none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility.active:hover::before {
  color: #CC0000;
  content: "\f00d";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility.active:not(.disabled):hover:not(.disabled) {
  background: transparent none;
  box-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-columnVisibility.active::before {
  color: #538200;
  content: "\f00c";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}
div.dt-button-collection button.dt-button.buttons-html5, div.dt-button-collection button.dt-button.buttons-print {
  background: #FFF none;
  border: 1px solid #EEE;
  box-shadow: none;
  font-size: 1.2em;
  margin: 0 0 4px 0;
  padding: 0.3em 0.7em;
  text-shadow: none;
}
div.dt-button-collection button.dt-button.buttons-html5:hover, div.dt-button-collection button.dt-button.buttons-print:hover {
  background: #FFC none;
}
div.dt-button-collection button.dt-button.buttons-html5:not(.disabled)::before {
  color: #222BAC;
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}
div.dt-button-collection button.dt-button.buttons-excel:not(.disabled)::before {
  content: "\f1c3";
}
div.dt-button-collection button.dt-button.buttons-csv:not(.disabled)::before {
  content: "\f0ce";
}
div.dt-button-collection button.dt-button.buttons-copy:not(.disabled)::before {
  content: "\f0c5";
}
div.dt-button-collection button.dt-button.buttons-print:not(.disabled)::before {
  color: #222BAC;
  content: "\f02f";
  display: inline-block;
  font-family: FontAwesome;
  margin-right: 0.5em;
  width: 1em;
}

.table_entries {
  clear: both;
  display: flex;
}

.table_controls {
  border-top: 1px solid #EEE;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: 0.5em;
  padding-top: 0.5em;
}

@media (min-width: 1275px) {
  .dt-button-text {
    display: inline;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.first, .dataTables_wrapper .dataTables_paginate .paginate_button.last {
    display: inline-block;
  }
}
@media only screen and (min-width: 500px) {
  .dataTables_wrapper .dataTables_length {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: left;
  }
}
@media only screen and (min-width: 950px) and (max-width: 1125px) {
  .dt-button-text {
    display: none;
  }
}
@media only screen and (min-width: 950px) {
  .table_entries,
  .table_controls {
    border-top: 0;
    clear: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1500px) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button,
  .dataTables_wrapper .dataTables_paginate span .ellipsis {
    display: inline-block;
  }
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-ms-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  animation: none;
  background: transparent;
  border-radius: 5px;
  border: 0;
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  gap: 2px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
  background: #ffffff;
  box-shadow: 1px 1px 3px 0 #666;
}
.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #ffffff;
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #ffffff;
}
.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*!
  /*rtl:begin:ignore*/
  /*
  */
  left: 0;
  /*!
  /*rtl:end:ignore*/
  /*
  */
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*!
  /*rtl:begin:ignore*/
  /*
  */
  right: 0;
  /*!
  /*rtl:end:ignore*/
  /*
  */
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #538200;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 25px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(0, 0, 0, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 8ch;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 10l5 5 5-5z"/%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3c/svg%3e');
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: 90%;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: 120%;
  margin: -1px 0 0 0;
  outline: none;
  padding: 4px 28px 4px 4px;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange, .flatpickr-day.today.inRange, .flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #538200;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #538200;
  background: #538200;
  color: white;
}
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #FFF4C6;
  box-shadow: none;
  color: #000;
  border-color: #FFF4C6;
}
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #FFF4C6;
}
.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(0, 0, 0, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #FFF4C6, 5px 0 0 #FFF4C6;
}
.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(0, 0, 0, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #000;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #000;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #000;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #000;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover, .flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eeeeee;
}

.flatpickr-input {
  background-repeat: no-repeat;
  background-position: 3px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23369' class='bi bi-calendar3'%3E%3Cg stroke-width='1.333'%3E%3Cpath d='M10.5 0h-9A1.5 1.5 0 0 0 0 1.5v9A1.5 1.5 0 0 0 1.5 12h9a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 10.5 0zM.75 2.893c0-.355.336-.643.75-.643h9c.414 0 .75.288.75.643v7.714c0 .355-.336.643-.75.643h-9c-.414 0-.75-.288-.75-.643z'/%3E%3Cpath d='M4.875 5.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zM2.625 7.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm-6.75 2.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5zm2.25 0a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z'/%3E%3C/g%3E%3C/svg%3E");
  border-style: inset;
  border-width: 1px;
  padding: 3px 3px 3px 20px;
}
.flatpickr-input:focus {
  border-radius: 0;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}

.flatpickr-day {
  border-radius: 0;
}

.flatpickr_wrapper {
  white-space: nowrap;
}

/* Shortcut buttons plugin */
.shortcut-buttons-flatpickr-wrapper {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-label {
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 5px;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons {
  display: flex;
  flex-flow: row wrap;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons .shortcut-buttons-flatpickr-button {
  background: transparent none;
  border: 0;
  color: #006100;
  font-size: 90%;
  margin: 2px;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons .shortcut-buttons-flatpickr-button:active {
  box-shadow: none;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons .shortcut-buttons-flatpickr-button:disabled {
  color: #999;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons .shortcut-buttons-flatpickr-button:disabled:hover {
  color: #999;
}
.shortcut-buttons-flatpickr-wrapper .shortcut-buttons-flatpickr-buttons .shortcut-buttons-flatpickr-button:hover {
  text-decoration: underline;
}

@font-face {
  font-family: "poppins";
  font-weight: 400;
  font-style: italic;
  src: local("Poppins-Italic"), url("fonts/poppins-italic-webfont.woff2") format("woff2"), url("fonts/poppins-italic-webfont.woff") format("woff");
}
@font-face {
  font-family: "poppins";
  font-style: 700;
  font-weight: italic;
  src: local("Poppins-BoldItalic"), url("fonts/poppins-bolditalic-webfont.woff2") format("woff2"), url("fonts/poppins-bolditalic-webfont.woff") format("woff");
}
@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins-Regular"), url("fonts/poppins-regular-webfont.woff2") format("woff2"), url("fonts/poppins-regular-webfont.woff") format("woff");
}
@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins-Bold"), url("fonts/poppins-bold-webfont.woff2") format("woff2"), url("fonts/poppins-bold-webfont.woff") format("woff");
}
::selection {
  background: #C00832;
  color: #FFFFFF;
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
button {
  box-shadow: unset;
  text-shadow: unset;
}

.page-section {
  margin-bottom: 1rem;
  padding: 1rem;
}
.page-section:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action) {
  background-color: white;
}
.page-section + .page-section {
  margin-top: 1em;
}

a:hover, a:active, a:focus {
  text-decoration: underline;
}
a:hover .term {
  color: #000;
  text-decoration: underline;
}
a.authlink {
  background-color: #e3f1df;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 4px;
}
a.edit-patronimage {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
a.edit-patronimage:hover {
  text-decoration: none;
  color: #333;
  background-color: #dadada;
}
a.cartRemove {
  color: #CC3333;
  font-size: 90%;
  margin: 0;
  padding: 0;
}
a.clear_date {
  color: #CC0000;
  font-size: 130%;
  vertical-align: middle;
}
a.clear_date:hover {
  color: #ff8080;
  text-decoration: none;
}
a.settings i, a.settings img {
  text-align: center;
  color: #C00832;
  border: solid 3px #f6009a;
  border-radius: 50%;
  background-color: transparent;
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  display: flex;
}
a.settings:hover {
  color: #C00832;
  text-decoration: none;
  font-weight: bold;
}
a.settings:hover i, a.settings:hover img {
  border-color: #C00832;
  background-color: transparent;
  color: #C00832;
}
a.settings img {
  max-width: 30px;
  height: auto;
  padding: 10px;
}
a.csv {
  background-image: url("../img/famfamfam/silk/page_white_excel.png");
}
a.dropdown-toggle {
  white-space: nowrap;
}
a.incart {
  color: #666;
}
a.disabled {
  color: #999999;
}
a.document {
  background-position: left middle;
  background-repeat: no-repeat;
  display: inline-block;
  min-height: 20px;
  padding-left: 20px;
}
a.highlight_toggle {
  display: none;
}
a .localimage img {
  border: 1px solid #0000CC;
  margin: 0 0.5em;
  padding: 0.3em;
}
a.pdf {
  background-image: url("../img/famfamfam/silk/page_white_acrobat.png");
}
a.submit {
  background: #FFF none;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  display: inline-block;
}
a.submit:hover {
  background-color: #e0e0e0;
}
a.submit:hover:active {
  box-shadow: none;
}
a.submit:active:hover {
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
a.submit:active {
  border: 1px inset #999999;
}
a.submit:disabled {
  background: #EEE none;
  border: 1px solid #C0C0C0;
}
a.term {
  text-decoration: underline;
}
a.xml {
  background-image: url("../img/famfamfam/silk/page_white_code.png");
}

#resetZ3950Search {
  font-size: 110%;
  color: #C00832;
}

a .ctrl_link {
  display: inline-block;
  padding-right: 1rem;
}

aside h5 {
  font-size: 100%;
  margin: 0.5em 0;
}
aside fieldset.brief {
  padding: 0.4em 0.7em;
}
aside fieldset.brief button + button,
aside fieldset.brief input + input {
  margin-left: 5px;
}
aside fieldset.brief fieldset {
  border-left: 2px solid #CCC;
  border-radius: 0;
  margin: 0.5em 0;
  padding: 0.5em;
}
aside fieldset.brief fieldset legend {
  font-size: 95%;
  margin: 0;
}
aside fieldset.brief fieldset.action {
  border: 0;
  margin-left: 0;
  padding: 0.5em 0;
}
aside fieldset.brief input[type=text],
aside fieldset.brief select {
  height: calc(1.5em + 0.75rem + 2px);
}
aside fieldset.brief label {
  color: #696969;
  display: block;
  margin: 0.5em 0 0 0;
}
aside fieldset.brief li {
  margin-top: 0;
  padding: 0;
}
aside fieldset.brief li.checkbox label {
  display: inline;
  margin-left: 0;
}
aside fieldset.brief li.dateinsert label {
  display: inline;
}
aside fieldset.brief li.dateinsert span.label {
  display: inline;
}
aside fieldset.brief li.radio {
  padding: 0.7em 0;
}
aside fieldset.brief li.radio input {
  padding: 0.3em 0;
}
aside fieldset.brief li.radio label {
  display: inline;
}
aside fieldset.brief li.radio span.label {
  display: inline;
}
aside fieldset.brief ol {
  margin: 0;
  padding: 0;
}
aside fieldset.brief select,
aside fieldset.brief [type=text] {
  width: 100%;
}
aside fieldset.brief .flatpickr-input {
  margin-right: 3px;
  width: calc(100% - 20px);
}
aside fieldset.brief + .action {
  background-color: #FFF;
  display: flex;
  margin-left: 0;
  padding: 1rem;
}
aside fieldset.brief + .action input, aside fieldset.brief + .action button, aside fieldset.brief + .action a {
  display: block;
  flex-grow: 1;
}

#menu, #navmenulist, #serialmenu {
  background-color: #e6e6e6;
  display: block;
  padding: 1em 0 1em 0;
}
#menu h5, #navmenulist h5, #serialmenu h5 {
  padding-left: 0.5em;
  color: #666;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 0;
}
#menu ul, #navmenulist ul, #serialmenu ul {
  margin-bottom: 10px;
  padding-left: 0;
}
#menu ul ul, #navmenulist ul ul, #serialmenu ul ul {
  font-size: 75%;
}
#menu ul li, #navmenulist ul li, #serialmenu ul li {
  list-style: none;
}
#menu ul li a, #navmenulist ul li a, #serialmenu ul li a {
  border-left: 5px solid #E6E6E6;
  color: #000;
  display: block;
  text-decoration: none;
  padding: 0.7em 0.3em 0.7em 1.2em;
}
#menu ul li.active > a,
#menu ul li a:hover,
#menu ul li a.current, #navmenulist ul li.active > a,
#navmenulist ul li a:hover,
#navmenulist ul li a.current, #serialmenu ul li.active > a,
#serialmenu ul li a:hover,
#serialmenu ul li a.current {
  background-color: #F3F4F4;
  text-decoration: none;
  color: #C00832;
  border-left: solid 5px #C00832;
  font-weight: bold;
}
#menu ul li a:hover:not(.current), #navmenulist ul li a:hover:not(.current), #serialmenu ul li a:hover:not(.current) {
  border-left: solid 5px #f6009a;
  font-weight: normal;
}
#menu ul li.active > a:hover, #navmenulist ul li.active > a:hover, #serialmenu ul li.active > a:hover {
  border-left: solid 5px #C00832;
  font-weight: bold;
}

#admin_preferences #menu ul ul {
  background-color: #f3f4f4;
  font-size: 85%;
}

div {
  display: block;
}
div.mainmenu {
  display: inline;
}
div.koha-mainpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.settings-links {
  display: inline;
}
div.settings-links ul {
  float: right;
}
div.settings-links ul li {
  list-style-type: none;
  margin-bottom: 0;
  height: auto;
  width: 150px;
  padding: 5px 10px;
}
div.settings-links ul li a,
div.settings-links ul li a:visited,
div.settings-links ul li a:link {
  color: #000;
}

button {
  background: #FFF none;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
button:hover {
  background-color: #e0e0e0;
}
button:hover:active {
  box-shadow: none;
}
button:active:hover {
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
button:disabled {
  background: #EEE none;
  border: 1px solid #C0C0C0;
}
button.closebtn {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

#add_to_patron_list_submit {
  color: #C00832;
}
#add_to_patron_list_submit:hover {
  border: 0;
}

main::after {
  clear: both;
  content: " ";
  display: table;
}

body {
  font-family: -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  line-height: 1.22;
  padding: 0 0 4em;
  text-align: left;
  background-color: #F3F4F4;
}
body.nobackdrop .modal-backdrop {
  opacity: 0;
}

br.clear {
  clear: both;
  line-height: 1px;
}

form {
  display: inline;
}
form.confirm {
  display: block;
  font-size: 110%;
  line-height: 130%;
}
form.confirm ul {
  padding: 1em 0;
}
form.confirm ul li {
  list-style-type: none;
}
form.confirm .notification_method {
  background-color: #FFE;
  border: 1px solid #CCC;
  border-radius: 5px;
  display: inline-block;
  margin: 0.5em 0;
  padding: 0.1em 0.3em;
}
form.confirm .notification_method.none {
  background-color: #EEE;
}
form.confirm .hold-found-barcode {
  display: inline-block;
  font-size: 90%;
}

h1 {
  font-size: 161.6%;
  font-weight: bold;
}

h2 {
  font-size: 150%;
  font-weight: bold;
  color: #696969;
}

h3 {
  font-size: 131%;
  font-weight: bold;
  color: #696969;
}

h4 {
  font-size: 116%;
  font-weight: bold;
}

h5 {
  font-size: 100%;
}

h6 {
  font-size: 93%;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5em 0;
}

hr {
  clear: both;
  margin: 1em 0;
}

p {
  margin: 0.7em 0;
}

#patron_search .address {
  font-size: 100%;
}

strong {
  font-weight: bold;
}
strong em {
  font-style: italic;
  font-weight: bold;
}
em strong {
  font-style: italic;
  font-weight: bold;
}

em,
cite {
  font-style: italic;
}

input:focus,
textarea:focus {
  border-color: #C00832;
  border-radius: 4px;
}

input[type=submit] {
  background: #FFC32B none;
  border: 1px solid #FFC32B;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.5em 1em;
}
input[type=submit]:hover, input[type=submit]:active, input[type=submit]:focus {
  background: #ffd15e none;
  border: 1px solid #FFC32B;
}
input[type=submit]:active, input[type=submit]:focus {
  box-shadow: inset 0 0 0 1px #edb423;
}
input[type=reset], input[type=button] {
  background: #FFF none;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.5em 1em;
}
input[type=reset]:hover, input[type=button]:hover {
  background-color: #e0e0e0;
}
input[type=reset]:hover:active, input[type=button]:hover:active {
  box-shadow: none;
}
input[type=reset]:active:hover, input[type=button]:active:hover {
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
input[type=reset]:disabled, input[type=button]:disabled {
  background: #EEE none;
  border: 1px solid #C0C0C0;
}
input[type=checkbox], input[type=radio] {
  height: unset;
  margin: 3px 3px 0 5px;
}
input.submit {
  background: #FFC32B none;
  border: 1px solid #FFC32B;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.5em 1em;
}
input.submit:hover, input.submit:active, input.submit:focus {
  background: #ffd15e none;
  border: 1px solid #FFC32B;
}
input.submit:active, input.submit:focus {
  box-shadow: inset 0 0 0 1px #edb423;
}
input.submit:disabled {
  background: #EEE none;
  border: 1px solid #C0C0C0;
}

.input-warning {
  background-color: #FF9;
  border-color: #900;
}

label,
.label {
  color: #000;
  display: inline;
  font-size: inherit;
  font-weight: normal;
  max-width: inherit;
  padding: 0;
  white-space: normal;
}
label input[type=checkbox], label input[type=radio],
.label input[type=checkbox],
.label input[type=radio] {
  margin-top: 0;
}
label.circ_barcode,
.label.circ_barcode {
  display: block;
  font-size: 105%;
  font-weight: bold;
  margin-bottom: 1rem;
  max-width: 75%;
}
label.required,
.label.required {
  color: #CC0000;
}

.subfield-label {
  font-style: italic;
}
.subfield-label span.subfield-code {
  font-weight: bold;
}

.members-update-table {
  padding-top: 10px;
}

.main {
  margin-bottom: 30px;
  margin-top: 1em;
}

.main > .row > div > aside, main > .row > div > aside {
  margin-left: -15px;
}

#login_controls {
  padding: 0.4em 0.5em;
  position: absolute;
  right: 0.5em;
}

ul {
  padding-left: 1.1em;
}
ul li {
  list-style-type: disc;
}
ul li input.submit {
  font-size: 87%;
  padding: 2px;
}
ul li li {
  list-style-type: circle;
}
ul.budget_hierarchy {
  margin-left: 0;
  padding-left: 0;
}
ul.budget_hierarchy li {
  display: inline;
}
ul.budget_hierarchy li::after {
  content: " -> ";
}
ul.budget_hierarchy li:first-child::after {
  content: "";
}
ul.budget_hierarchy li:last-child::after {
  content: "";
}
ul.fa-ul li {
  list-style-type: none;
}

ol {
  padding-left: 1.5em;
}
ol li {
  list-style: decimal;
}
ol.bibliodetails {
  float: left;
  margin: 0 0 1em 1em;
}

.cart-controls {
  border-top: 1px solid #E8E8E8;
  padding: 7px 0;
}

#editions table,
#editions td {
  border: 0;
}

.overdue,
.debit {
  color: #CC0000;
  font-weight: bold;
}

.strong {
  font-weight: bold;
}

.problem {
  color: #990000;
  font-weight: bold;
}

fieldset + fieldset.action {
  margin-top: -1em;
}
fieldset.lastchecked {
  margin-bottom: 0;
  margin-right: 0;
}
fieldset.standard {
  background-color: #F4F8F9 !important;
}
fieldset.contrast {
  background-color: #F3F3F3 !important;
}
fieldset.action {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  clear: both;
  float: none;
  padding: 1rem 1rem 1rem 0;
  width: auto;
}
fieldset.action a.cancel {
  padding-left: 1em;
}
fieldset.brief div.hint {
  margin-bottom: 0.4em;
}
fieldset.brief label,
fieldset.brief span.label {
  display: block;
  font-weight: bold;
  padding: 0.3em 0;
  text-align: left;
}
fieldset.brief label.inline,
fieldset.brief span.label.inline {
  display: inline;
  float: none;
  margin-left: 1em;
  width: auto;
}
fieldset.brief li {
  margin-top: 0.5em;
}
fieldset.brief li:first-child {
  margin-top: 0;
}
fieldset.brief li[aria-disabled=true] {
  color: #999;
}
fieldset.brief li.inline {
  display: inline;
  float: none;
  margin-left: 1em;
  width: auto;
}
fieldset.brief li.radio label, fieldset.brief li.checkbox label {
  display: inline;
}
fieldset.brief ol,
fieldset.brief li {
  list-style-type: none;
  padding-left: 0;
}
fieldset.brief .action {
  margin: 0;
  padding: 1em 0 0 0;
}
fieldset ol li {
  list-style-type: none;
  padding: 0.3em 0;
}

details > summary {
  cursor: pointer;
}
details > summary::before {
  content: "\f0da";
  display: inline-block;
  font-family: FontAwesome;
  width: 1em;
}
details > summary.checkouts-by-itemtype li {
  display: inline-block;
}

details[open] > summary::before {
  content: "\f0d7";
}

#floating-save {
  background-color: rgba(185, 216, 217, 0.6);
  bottom: 3%;
  position: fixed;
  right: 1%;
  width: 150px;
}

#sub-header {
  display: flex;
  justify-content: space-between;
}

#breadcrumbs, #shortcut {
  background-color: transparent;
  clear: both;
  margin: 0;
  padding: 0.8em 0.5em 0.8em 10px;
  position: relative;
}
#breadcrumbs .title, #shortcut .title {
  font-style: italic;
  font-weight: normal;
}

#breadcrumbs a[href="/cgi-bin/koha/mainpage.pl"] {
  color: #C00832;
  visibility: hidden;
  font-size: 1px;
  letter-spacing: -1px;
}
#breadcrumbs a[href="/cgi-bin/koha/mainpage.pl"]::before {
  content: "\f015";
  font-family: "FontAwesome";
  font-style: normal;
  font-size: 14px;
  visibility: visible;
  padding-right: 0.3em;
}
#breadcrumbs a[href="/cgi-bin/koha/mainpage.pl"]:hover {
  text-decoration: none;
}

#header + #breadcrumbs {
  margin-top: 1em;
}
#header > .container-fluid {
  padding: 0;
}

div.action {
  background-color: transparent;
  border: 0;
  clear: both;
  float: none;
  margin: 0.9em 0 0;
  padding: 0.4em;
  width: auto;
}
div .renew_formfield {
  margin-bottom: 1em;
}
div .circmessage {
  padding: 0.4em;
}
div .circmessage .circmessage {
  margin-bottom: 0.3em;
}
div.first fieldset {
  margin-right: 0;
}
div.help {
  margin: 0.9em 0 0;
}
div.justify {
  text-align: justify;
}
div.note {
  background-color: #CFE2FF;
  margin: 0.5em 0;
  padding: 0.5em;
}
div.note i.fa-exclamation {
  color: #CC0000;
  font-style: italic;
  padding: 0 0.3em;
}
div.rules {
  display: block;
}
div[class$=_table_controls] {
  padding: 0.7em 0;
}
div.results {
  padding: 0.7em 0;
}
div.rule {
  background-color: #F4F8F9;
  border: 2px solid #bfd9b9;
  border-radius: 5px;
  margin: 0.3em;
  padding: 0.3em;
}
div.lastchecked {
  background-color: #FFF;
  border: 2px solid #BCDB89;
  padding: 0.2em 1em;
}
div.listgroup {
  clear: left;
}
div.listgroup h4 {
  font-style: italic;
}
div.listgroup h4 a {
  font-size: 80%;
}
div.sysprefs h3 {
  margin: 0.2em 0 0.2em 0.4em;
}
div.sysprefs dl {
  margin-left: 1.5em;
}
div.sysprefs.hint {
  float: right;
  margin: 0.7em;
  padding: 0.5em;
  width: 25%;
}
div.rows {
  margin: 0;
  padding: 0;
  width: 100%;
}
div.rows + div.rows {
  margin-top: 0.6em;
}
div.rows li {
  border-bottom: 1px solid #EEE;
  list-style-type: none;
  padding: 0.275em;
  width: 100%;
}
div.rows ol {
  list-style-type: none;
  padding: 0.5em 1em 0 0;
}
div.rows ol li li {
  border-bottom: 0;
}
div.rows p {
  margin-left: 10em;
}
div.rows span.label {
  display: inline-block;
  font-weight: bold;
  margin-right: 1em;
  padding-top: 0;
  text-align: left;
  vertical-align: top;
  width: 9em;
  white-space: normal;
}
div.pages {
  margin: 0.5em 0;
}
div.pages a {
  font-weight: bold;
  padding: 1px 5px;
  text-decoration: none;
}
div.pages a:link, div.pages a:visited {
  background-color: #EEEEEE;
  color: #3366CC;
}
div.pages a:hover, div.pages a:active {
  background-color: #FFC;
}
div.pages .current,
div.pages .currentPage {
  background-color: #E6FCB7;
  color: #666;
  font-weight: bold;
  padding: 1px 5px;
}
div.pages .inactive {
  background-color: #F3F3F3;
  color: #BCBCBC;
  font-weight: bold;
  padding: 1px 5px;
}
div .browse {
  margin: 0.5em 0;
}

#sales {
  display: none;
}

.single-line {
  white-space: nowrap;
}

.ex {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

dt {
  font-weight: bold;
}

dd {
  font-size: 90%;
  font-weight: normal;
  padding: 0.2em 0.2em 0.2em 2.5em;
}

#disabled a {
  color: #999;
}
#disabled a:hover {
  color: #999;
}

#disabled2 a {
  color: #999;
}

.patroninfo {
  margin-right: 0.5em;
  padding-top: 1em;
  background-color: #e6e6e6;
  color: #000;
  left: -10px;
  width: 100%;
  padding-bottom: 25px;
}
.patroninfo h5 {
  margin-bottom: 0;
  padding: 0.5em 0.9em;
  font-weight: bold;
  color: #000;
}
.patroninfo h5:empty {
  border-right: 0;
}
.patroninfo h5 li.patrondateofbirth {
  font-size: 75%;
  font-weight: normal;
  list-style-type: none;
  text-align: center;
}
.patroninfo ul {
  border: 0;
  margin: 0;
  padding: 0 0.9em;
}
.patroninfo ul li {
  list-style-type: none;
  margin: 0;
}
.patroninfo > div {
  width: 100%;
}

.patroninfo-section {
  margin-bottom: 1rem;
  padding: 1rem;
}
.patroninfo-section:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action) {
  background-color: white;
}

.patroninfo-heading {
  padding: 0.5em;
  margin-bottom: 0.3em;
  clear: both;
}
.patroninfo-heading h3 {
  display: inline-block;
}
.patroninfo-heading .btn {
  float: right;
}

/* Patron image */
.patronimage-container {
  padding: 0.2em;
  position: relative;
}
.patronimage-container:hover .patronimage {
  opacity: 0.8;
}
.patronimage-container:hover .patronimage-controls {
  opacity: 1;
}

.patronimage {
  border: 1px solid #EEE;
  display: block;
  max-width: 160px;
  margin: auto;
  opacity: 1;
  transition: 0.2s ease;
}
.patronimage.empty {
  background: transparent url("../img/patron-blank.min.svg") center 5px no-repeat;
  height: 125px;
  padding: 0;
  width: 80%;
}

.patronimage-controls {
  left: 50%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 80%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease;
}

.patronimage-control {
  padding: 1em 2em;
}

#patronImageEdit input[type=file] {
  display: inline-block;
}

#addColumn, #delColumn {
  color: #C00832;
  padding: 0.3em;
  border: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 100%;
}
#addColumn:hover, #delColumn:hover {
  text-decoration: underline;
}

.patronviews {
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  margin-bottom: 0.5em;
  padding: 0.5em 0;
}

.column-tool {
  font-size: 80%;
}

.hint {
  color: #666;
  font-size: 95%;
}

.dropdown-menu {
  background-color: #F3F4F4;
}
.dropdown-menu li {
  list-style-type: none;
}
.dropdown-menu li a {
  color: #000;
  padding: 0.4em 20px;
}
.dropdown-menu li a:hover {
  background-color: #DADADA;
  background-image: none;
  color: #000;
  text-decoration: none;
}

.readonly,
input[type=text]:read-only {
  background: #EEE url("../img/locked.png") center left no-repeat;
  border-style: inset;
  border-width: 1px;
  cursor: default;
  padding-left: 15px;
}

.readonly:focus,
input[type=text]:read-only:focus {
  border-color: unset;
  border-radius: unset;
}

.checkedout {
  color: #999999;
  font-style: italic;
}

.subfield_not_filled {
  background-color: #FFFF99;
}

.important_subfield_not_filled {
  background-color: #FFFFCC;
}

.content_hidden {
  display: none;
  visibility: hidden;
}

.content_visible {
  display: block;
  visibility: visible;
}

#z3950searcht table {
  border: 0;
  padding: 20px;
}

#z3950_search_targets {
  height: 338px;
  overflow-y: auto;
}

#z3950_search_targets_acq {
  height: 308px;
  overflow-y: auto;
}

.z3950checks {
  padding-left: 1em;
}

.error {
  color: #CC0000;
}

i.error {
  color: #CC0000;
}
i.success {
  color: #C00832;
}
i.warn {
  color: #FFA500;
}

.circ-setting {
  font-size: 95%;
  padding: 0.3em 0;
}
.circ-setting input {
  vertical-align: middle;
}
.circ-setting input.flatpickr-input {
  max-width: 11em;
}
.circ-setting label {
  font-size: inherit;
  font-weight: normal;
}

.circ-settings {
  border-top: 2px solid #EEE;
  border-radius: 0;
  display: none;
  margin-left: -1em;
  margin-right: -1em;
  margin-top: 1em;
  padding: 1em 1em 0;
}

#show-circ-settings {
  display: inline;
  position: relative;
  right: 25px;
  opacity: 0.5;
}
#show-circ-settings:hover {
  opacity: 1;
}
#show-circ-settings a {
  color: rgb(105, 105, 105);
}
#show-circ-settings a:hover {
  text-decoration: none;
}

.checkin-active-setting {
  background-color: #FFC;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0 rgba(102, 102, 102, 0.5);
  margin: 0.5em;
  padding: 0.5em;
}

.form-control-group {
  white-space: nowrap;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group div.hint {
  margin: 5px 0;
}

.blocker,
.inaccurate-item-statuses {
  color: #990000;
}

.circmessage li {
  list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 25 25'%3E%3Cpath fill='%23999' d='M9.66.95h4.56l9.21 11.85-9.21 10.53H9.66l5.08-10.53z'/%3E%3C/svg%3E");
  margin-bottom: 0.4em;
}

#circmessages,
#patron_messages {
  margin-bottom: 1rem;
  padding: 1rem;
}
#circmessages:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action),
#patron_messages:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action) {
  background-color: white;
}

.dialog {
  margin: 1em auto;
  padding: 0.5em;
  text-align: center;
  width: 65%;
  max-width: 600px;
}
.dialog a.approve {
  display: inline-block;
}
.dialog button,
.dialog a.approve {
  background: #FFF none;
  border: 1px solid #555;
  margin: 0.4em;
  padding: 0.4em;
  white-space: pre-line;
}
.dialog button:active,
.dialog a.approve:active {
  border: 1px inset #999999;
}
.dialog button:hover,
.dialog a.approve:hover {
  background-color: #FFC;
}
.dialog h2,
.dialog h3,
.dialog h4 {
  margin: 5px auto;
  text-align: center;
}
.dialog input {
  background-color: #FFFFFF;
  margin: 0.4em;
  padding: 0.4em;
}
.dialog input:hover {
  background-color: #FFC;
}
.dialog input[type=submit] {
  background: #FFF none;
}
.dialog input[type=submit].approve {
  background-color: #FEC32C;
  color: #000;
  border: 0;
  background-image: none;
  margin-top: 5px;
}
.dialog input[type=submit].approve:hover, .dialog input[type=submit].approve:active, .dialog input[type=submit].approve:focus {
  background-color: rgba(254, 194, 44, 0.6235294118);
}
.dialog input[type=submit].deny {
  background-color: transparent;
  color: #696969;
  border: #696969 double 1px;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
}
.dialog input[type=submit].deny:hover {
  font-weight: bold;
  background-color: #F1F1F1;
  border: #696969 double 1px;
  text-decoration: none;
}
.dialog input.flatpickr-input {
  padding: 3px 3px 3px 20px;
}
.dialog li {
  list-style-position: inside;
}
.dialog p {
  margin-top: 0;
}
.dialog p + p {
  margin-top: 5px;
}
.dialog table {
  margin: 0.5em auto;
}
.dialog table td {
  text-align: left;
}
.dialog table th {
  text-align: right;
}
.dialog.message {
  background-color: #CFE2FF;
  text-align: center;
}
.dialog.message ul,
.dialog.message h5 {
  padding-left: 25%;
  text-align: left;
}
.dialog.message ul + h4 {
  margin-top: 0.7em;
}
.dialog.alert, .dialog.error {
  background-color: rgb(255, 237, 135);
  color: inherit;
  text-align: center;
  text-shadow: none;
}
.dialog.alert strong, .dialog.error strong {
  color: #900;
}
.dialog.alert.list, .dialog.error.list {
  text-align: left;
}
.dialog.alert.list h2,
.dialog.alert.list h3,
.dialog.alert.list h4, .dialog.error.list h2,
.dialog.error.list h3,
.dialog.error.list h4 {
  margin: 1em 0;
  text-align: left;
}

.approve,
.success {
  background-color: #FEC32C;
}
.approve i.fa,
.success i.fa {
  color: #C00832;
}

.deny i.fa {
  color: #CC0000;
}

.new i.fa {
  color: #425FAF;
}

.warning i.fa-exclamation-triangle {
  color: #FFD700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}

.accesskey {
  text-decoration: underline;
}

.missing {
  background-color: #FFFFCC;
}

.term {
  background-color: #FFC;
  color: #990000;
}

.shelvingloc {
  display: block;
  font-style: italic;
}

.bundled {
  display: block;
  font-style: italic;
}

#closewindow {
  margin-top: 2em;
  text-align: center;
}
#closewindow a {
  font-weight: bold;
}

.barcode {
  font-size: 100%;
  vertical-align: middle;
  font-style: italic;
  width: 250px;
  height: 30px;
  padding-left: 5px;
  padding-right: 30px;
  border-radius: 0;
}

li.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patronbriefinfo li.email {
  font-size: 87%;
  padding: 0 10px 0 0;
  width: 90%;
}

.empty {
  color: #666;
}

.address {
  font-size: 110%;
}
.address li {
  list-style-type: none;
}

.title {
  font-weight: bold;
}

.hold {
  float: right;
  font-size: 90%;
  margin: 0;
}

.thumbnail {
  display: block;
  margin: auto;
}

.thumbnails li {
  display: inline-block;
  list-style-type: none;
  margin: 4px;
}
.thumbnails .remove {
  border-top: 1px solid #EEE;
  display: block;
  font-size: 90%;
  margin: 4px -4px 2px -4px;
  padding-top: 0.5em;
  text-align: center;
}
.thumbnails + p {
  border-top: 1px solid #eee;
  margin-top: 1em;
  padding-top: 1em;
}

#catalogue_stats_wrapper, #tbl_cash_register_stats_wrapper, #resulttable_wrapper, #numberpatternst_wrapper {
  background-color: #FFFFFF;
  margin-top: 20px;
  box-shadow: 8px 8px 12px rgba(170, 170, 170, 0.356);
  padding: 20px;
}

#searchresults {
  background-color: #FFFFFF;
  margin-top: 20px;
  min-width: 100%;
  width: fit-content;
  padding: 20px;
}
#searchresults fieldset {
  box-shadow: none;
}
#searchresults h3 {
  padding-bottom: 0.7em;
  margin: 0;
  color: #696969;
}
#searchresults td ul li {
  clear: left;
  font-size: 90%;
  list-style: url("../img/item-bullet.svg");
  padding: 0.2em 0;
  color: #000;
  top: 100%;
}
#searchresults td ul li.result_itype_image {
  list-style: none;
  list-style-type: none;
}
#searchresults td ul li img {
  float: left;
  margin: 3px 5px 3px -5px;
  max-width: 25px;
}
#searchresults span.status {
  clear: left;
  color: #900;
  display: block;
}
#searchresults span.unavailable {
  clear: left;
  display: block;
}
#searchresults table {
  width: 100%;
}
#searchresults table td {
  vertical-align: top;
}
#searchresults.unavailability strong {
  display: block;
}

#searchresults .address ul,
#table_borrowers .address ul {
  margin: 0;
  padding-left: 0;
}
#searchresults .address ul li,
#table_borrowers .address ul li {
  clear: none;
  float: left;
  list-style: none;
  margin-left: 1ch;
  padding: 0;
}
#searchresults .dropdown-menu,
#table_borrowers .dropdown-menu {
  background-color: #FFFFFF;
  color: #000;
  height: fit-content;
  top: 100%;
}
#searchresults .dropdown-menu a,
#table_borrowers .dropdown-menu a {
  color: #000;
}
#searchresults .dropdown-menu a:hover,
#table_borrowers .dropdown-menu a:hover {
  background-image: none;
  color: #000;
  text-decoration: underline;
  background-color: #FFFFFF;
}

.searchheader {
  background-color: #f3f4f4;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  font-size: 80%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 5px;
  width: 100%;
  color: #696969;
  display: block;
}
.searchheader.floating {
  border-radius: 0;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  margin-top: 0;
  margin-bottom: 0;
  z-index: 100;
}
.searchheader .btn-group > .btn:first-child {
  margin-left: 0.7em;
}
.searchheader form {
  float: right;
  padding: 5px 5px 3px 0;
}
.searchheader form.fz3950 {
  float: right;
  font-size: 125%;
  padding: 0 0 0 5em;
}
.searchheader form.fz3950bigrpad {
  float: right;
  font-size: 125%;
  padding: 5px 25em 0 0;
}
.searchheader .dropdown-menu {
  max-height: 50vh;
  overflow-y: auto;
}

#patron_search_selected {
  background-color: #fffbfc;
  border: 1px solid #C00832;
  border-color: #f96889 #fdcad6 #fdcad6 #f96889;
  border-radius: 3px;
  color: #333;
  display: inline-block;
  font-size: 11px;
  line-height: 1.5;
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

#clear-patron-selection {
  display: inline-block;
  margin-left: 1em;
}

.mini-inp {
  height: 12px;
  width: 30px;
}

#search-facets {
  background-color: #e6e6e6;
}
#search-facets h4 {
  background-color: #C00832;
  border-radius: 0 4px 0 0;
  color: #FFFFFF;
  font-size: 100%;
  margin: 0;
  padding: 0.4em 0.2em;
  text-align: center;
}
#search-facets ul {
  margin: 0;
  padding: 6px 12px;
}
#search-facets ul li {
  font-weight: bold;
  list-style-type: none;
}
#search-facets li li {
  font-size: 85%;
  font-weight: normal;
  margin-bottom: 2px;
  padding: 0.1em 0.2em;
}
#search-facets li.showmore {
  font-weight: bold;
  text-indent: 1em;
}

.facet-count {
  display: inline-block;
}

.bookcoverimg {
  text-align: center;
}

#biblio-cover-slider {
  border: 1px solid #bfd9b9;
  border-radius: 3px;
  margin: 5px;
  padding: 10px 5px 5px 5px;
  min-height: 175px;
}

.cover-slides {
  background: #FFF url("../img/spinner-small.gif") center center no-repeat;
}
.cover-slides .hint {
  font-size: 90%;
  padding: 0.5em 0;
}
.cover-slides a.nav-active:link, .cover-slides a.nav-active:visited {
  color: #85ca11;
}

td.actions {
  white-space: nowrap;
}
td.bookcoverimg {
  background: #FFF url("../img/spinner-small.gif") center center no-repeat;
  min-width: 120px;
  text-align: center;
}
td .cover-slides {
  background: transparent none;
  border: 0;
  margin: 0;
  min-height: unset;
  padding: 0;
}
td.credit, td.debit, td.total {
  text-align: right;
}
tfoot td.credit, tfoot td.debit, tfoot td.total {
  color: #000;
}

.highlight_toggle {
  color: #C00832;
  padding: 0.3em;
  border: 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  font-size: 100%;
}
.highlight_toggle:hover {
  text-decoration: underline;
}

.cover-image {
  display: none;
}
.cover-image img {
  height: auto;
  max-width: 100%;
}

.custom_cover_image img {
  max-width: 140px;
}

.cover-nav {
  display: inline-block;
  padding: 3px 4px;
}

.searchhighlightblob {
  font-size: 75%;
  font-style: italic;
}

#irregularity_summary {
  vertical-align: top;
}

#CheckAll,
#CheckNone,
#CheckPending {
  font-weight: normal;
  margin: 0 0.5em 0 0;
  color: #C00832;
}

.lost,
.dmg,
.wdn {
  color: #990000;
  display: block;
}

.bundled {
  display: block;
}

td.bundle {
  background-color: #FFC !important;
}

.datedue {
  color: #999;
  display: block;
  font-style: italic;
}

.waitinghere,
.credit {
  color: #669900;
}

#mainuserblock {
  border: 1px solid #E8E8E8;
  margin-top: 0.5em;
  padding: 0.5em;
}

.labeledmarc-table {
  border: 0;
}

.labeledmarc-label {
  border: 0;
  color: #000000;
  font-size: 11pt;
  font-style: italic;
  padding: 5;
}

.labeledmarc-value {
  border: 0;
  color: #000;
  font-size: 10pt;
  padding: 5;
}

#marcPreview table {
  border: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 95%;
  margin: 0.7em 0 0;
}
#marcPreview tbody tr:nth-child(2n+1) td {
  background-color: #FFFFFF;
}
#marcPreview td {
  border: 0;
  padding: 2px;
  vertical-align: top;
}
#marcPreview th {
  background-color: #FFFFFF;
  border: 0;
  padding: 2px;
  text-align: left;
  vertical-align: top;
  white-space: nowrap;
}
#marcPreview.modal-dialog {
  width: 80%;
}

.modal-dialog .dialog {
  border-radius: 0;
  border-width: 1px 0 0 0;
  clear: both;
  margin: 15px -15px -15px -15px;
  padding: 15px;
  text-align: left;
  width: unset;
}
.modal-dialog .dialog h3 {
  margin: unset;
  text-align: left;
}
.modal-dialog .dialog.alert {
  background: #FFFADE none;
  border-color: #E0C726;
}
.modal-dialog .dialog.message {
  background: #E8EDF6 none;
  border-color: #A4BEDD;
}
.modal-dialog.modal-wide {
  width: 80%;
}

#cartDetails {
  background-color: #352C2E;
  box-shadow: 1px 1px 3px 0 #666;
  color: #FFFFFF;
  display: none;
  margin: 0;
  padding: 10px;
  text-align: center;
  width: 180px;
  z-index: 50;
}

#cartmenulink {
  position: relative;
  left: 0;
  top: 0;
}

#basketcount span {
  display: inline;
  font-size: 90%;
  font-weight: normal;
  padding: 0;
}

#moremenu {
  display: none;
}

.results_summary {
  color: #202020;
  display: block;
  padding: 0 0 0.5em;
}
.results_summary a {
  font-weight: normal;
}
.results_summary .label {
  color: #707070;
}

.child_fund_amount {
  font-style: italic;
}

.number_box {
  font-size: 105%;
  line-height: 200%;
}
h3 .number_box {
  font-size: 70%;
}
.number_box a,
.number_box span {
  background-color: #fff6fc;
  border: 1px solid #ff43b9;
  border-radius: 4px;
  font-weight: bold;
  padding: 0.1em 0.4em;
  text-decoration: none;
}
.number_box a:hover,
.number_box span:hover {
  background-color: #ffc3e9;
}

.container {
  margin: 1em 0;
  padding: 1em;
}

.import_export {
  position: relative;
}
.import_export .export_ok {
  background: #E3E3E3 none;
  border: 0;
  cursor: pointer;
  margin-left: 20px;
  padding: 10px;
}
.import_export .import_export_options {
  background: #FFFFFF;
  border: 1px solid #CDCDCD;
  left: 60px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 1;
}

.import_export_options {
  background: #E3E3E3 none;
  border: 0;
  cursor: pointer;
  margin-left: 20px;
  padding: 10px;
}
.import_export_options .importing {
  background: none;
  padding: inherit;
}

.form_import .input_import {
  border: 1px solid #BCBCBC;
}

.importing {
  position: relative;
}
.importing .importing_msg {
  padding-bottom: 10px;
  padding-left: 10px;
}

.field_hint {
  color: #808080;
  font-style: italic;
  padding-left: 1em;
}

.m880 {
  display: block;
  float: right;
  padding-left: 20px;
  text-align: right;
  width: 50%;
}

#advsearches {
  margin-bottom: 1em;
}
#advsearches .tab-pane {
  margin: 0 1em 1em 0;
}
#advsearches .tab-pane table {
  border-collapse: separate;
  border-spacing: 5px;
  border-width: 0;
}
#advsearches .tab-pane td {
  border: 1px solid #EEE;
  padding: 0.3em 0.4em;
}

#circ_circulation_issue {
  position: relative;
}

#renew_as_unseen_label {
  margin-left: 1em;
}

#renew_as_unseen_checkbox {
  margin-right: 1em;
}

#clearscreen {
  position: absolute;
  right: 0;
  top: 0;
}
#clearscreen a {
  background-color: #EEE;
  border-radius: 0 0 0 5px;
  color: #CCC;
  display: block;
  font-size: 160%;
  font-weight: bold;
  padding: 0 0.7em 0.2em;
  text-decoration: none;
  text-shadow: 0 -1px 0 #666;
}
#clearscreen a:hover {
  color: #CC0000;
}

#printclearscreen,
#printclearscreenq {
  position: absolute;
  right: 43px;
  top: 0;
}
#printclearscreen a,
#printclearscreenq a {
  background-color: #EEE;
  border-radius: 0 0 0 5px;
  color: #CCC;
  display: block;
  font-size: 160%;
  font-weight: bold;
  padding: 0 0.7em 0.2em;
  text-decoration: none;
  text-shadow: 0 -1px 0 #666;
}
#printclearscreen a:hover,
#printclearscreenq a:hover {
  color: #CC0000;
}

.no-image {
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  border-radius: 3px;
  color: #979797;
  display: block;
  font-size: 86%;
  font-weight: bold;
  margin: 1em auto;
  padding: 1em;
  text-align: center;
  width: 75px;
}

#acqui_order_supplierlist .supplier + .supplier {
  border-top: 1px solid #EEEEEE;
  margin-top: 1em;
}
#acqui_order_supplierlist .suppliername {
  display: inline-block;
  margin: 0.5em 1em 0.5em 0;
}
#acqui_order_supplierlist .suppliername.inactive {
  color: #888;
}
#acqui_order_supplierlist .suppliername.inactive a:link,
#acqui_order_supplierlist .suppliername.inactive a:visited {
  color: #888;
}

#ADD-contact {
  margin: 0 0 8px 8px;
}

#contact-template {
  display: none;
}

.ui-widget-content {
  background: #FFFFFF none;
  border-radius: 4px;
  border: 3px solid #C00832;
  color: #222222;
}

.ui-widget-header {
  background: #b5dbad none;
  border: 1px solid #bfd9b9;
  color: #222222;
  font-weight: bold;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #bfd9b9;
  color: #555555;
  font-weight: normal;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #cad8c6 none;
  border: 1px solid #bfd9b9;
  color: #212121;
  font-weight: normal;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #FFF4C6;
  border: 1px solid #FED22F;
  color: #363636;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  background: #FEF1EC;
  border: 1px solid #CD0A0A;
  color: #CD0A0A;
}

.ui-autocomplete {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  cursor: default;
  position: absolute;
  z-index: 2000;
}
.ui-autocomplete.ui-menu li.ui-menu-item {
  padding: 3px 1em 3px 0.4em;
}
.ui-autocomplete.ui-menu li.ui-menu-item:hover {
  background: #e3f1df none;
  color: #212121;
  font-weight: normal;
}
.ui-autocomplete.ui-menu li.ui-menu-item .ui-state-active {
  background: transparent none;
  border: 0;
}
.ui-autocomplete.ui-menu li.ui-menu-item .ui-menu-item-wrapper {
  padding: unset;
}
.ui-autocomplete.ui-menu li.ui-menu-item .ui-state-active,
.ui-autocomplete.ui-menu li.ui-menu-item .ui-state-focus {
  background: #e3f1df none;
  color: #212121;
  font-weight: normal;
  margin: 0;
}

.ui-autocomplete-loading {
  background: #FFF url("../img/spinner-small.gif") right center no-repeat;
}

.ui-widget {
  font-family: inherit;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: inherit;
}

.toptabs {
  margin-top: 5px;
}

fieldset > .toptabs {
  background-color: #F3F4F4;
  padding: 1em;
}

.authref {
  font-style: normal;
  text-indent: 4em;
}

.seefrom,
.seealso {
  font-style: italic;
  text-indent: 2em;
}

#authfinderops {
  float: right;
}

.authorizedheading {
  font-weight: bold;
}

.authres_notes,
.authres_seealso,
.authres_otherscript {
  padding-top: 3px;
}

.authres_notes {
  font-style: italic;
}

.contents {
  width: 75%;
}
.contents .newline::after {
  content: "\a→ ";
  white-space: pre;
}
.contents .t {
  font-weight: bold;
  display: inline;
}
.contents .r {
  display: inline;
}

.contentblock {
  font-size: 95%;
  line-height: 135%;
  margin-left: 2em;
  position: relative;
}
.contentblock :first-child::before {
  content: "→ ";
}

#hierarchies {
  margin-bottom: 1em;
}
#hierarchies a {
  color: #C00832;
  font-weight: normal;
  text-decoration: underline;
}
#hierarchies a.jstree-anchor.jstree-hovered {
  background: transparent none;
  box-shadow: none;
  color: #a8072c;
}
#hierarchies a.jstree-anchor.jstree-clicked {
  background: transparent none;
  box-shadow: none;
  border: 0;
}

#didyoumeanopac,
#didyoumeanintranet {
  float: left;
  width: 260px;
}

.pluginlist {
  padding-bottom: 10px;
}

.plugin {
  margin: 0 1em 1em 0;
}

.pluginname {
  background-color: #e3f1df;
  cursor: move;
  margin: 0.3em;
  padding-bottom: 4px;
  padding-left: 0.2em;
}
.pluginname .ui-icon {
  float: right;
}

.plugindesc {
  padding: 0.4em;
}

.ui-sortable-placeholder {
  border: 1px dotted #000;
  height: 80px;
  visibility: visible;
}
.ui-sortable-placeholder * {
  visibility: hidden;
}

.ui-accordion-header,
.ui-widget-content .ui-accordion-header {
  font-size: 110%;
  font-weight: bold;
}

video {
  width: 480px;
}

.dropdown-header {
  border-top: 1px solid #EEE;
  color: #000;
  font-weight: bold;
  margin-top: 5px;
  padding-left: 10px;
}
.dropdown-header:first-child {
  border-top: 0;
}

nav {
  border: 0;
  display: block;
}
nav.breadcrumb {
  background-color: transparent;
  margin: 0;
}
nav.breadcrumb ol {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
nav.breadcrumb ol li {
  display: inline;
  color: #C00832;
  font-style: italic;
}
nav.breadcrumb ol li + li::before {
  background: transparent url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1yaWdodCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNy4zNyAwbDUuMzUgNy4zYy4yLjIuMi41MSAwIC43bC01LjM4IDhoLTQuMmw1LjY0LTguMzVMMy4xMy4wMXoiLz48L3N2Zz4=") 50% 50% no-repeat;
  background-size: 8px;
  content: "";
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
}
nav.breadcrumb ol li a:link, nav.breadcrumb ol li a:active, nav.breadcrumb ol li a:hover, nav.breadcrumb ol li a:focus {
  padding: 0.6em 0.3em;
}
nav.breadcrumb [aria-current=page] {
  color: #696969;
  text-decoration: none;
}

.navbar {
  background-color: #352C2E;
  border: 0;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border: 0;
}

.nav-pills li a {
  background-color: transparent;
}
.nav-pills li.active a:link, .nav-pills li.active a:visited {
  background-color: #C00832;
}
.nav-pills li.active a:active, .nav-pills li.active a:hover, .nav-pills li.active a:focus {
  background-color: #f6009a;
}

.pagination {
  margin: 0.5em 0;
}

button,
.btn {
  background: #FFF none;
  border: 1px solid #555;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
button:hover,
.btn:hover {
  background-color: #e0e0e0;
}
button:hover:active,
.btn:hover:active {
  box-shadow: none;
}
button:active:hover,
.btn:active:hover {
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
button.btn-default,
.btn.btn-default {
  color: #555;
}
button.btn-link,
.btn.btn-link {
  background: transparent none;
  border: 0;
}
button.btn-primary,
.btn.btn-primary {
  background: #FFC32B none;
  border: 1px solid #FFC32B;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.42857143;
  margin-bottom: 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
button.btn-primary:hover, button.btn-primary:active, button.btn-primary:focus,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background: #ffd15e none;
  border: 1px solid #FFC32B;
}
button.btn-primary:active, button.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  box-shadow: inset 0 0 0 1px #edb423;
}
button.btn-primary.dropdown-toggle,
.btn.btn-primary.dropdown-toggle {
  border-left: 1px solid #de9f00;
}
button.btn-danger,
.btn.btn-danger {
  background-color: #CC3333;
  border-color: #9B2323;
  color: #fff;
}
button.btn-danger:hover,
.btn.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #a81c29;
}
button.btn-danger:not(:disabled):not(.disabled).active, button.btn-danger:not(:disabled):not(.disabled):active,
.btn.btn-danger:not(:disabled):not(.disabled).active,
.btn.btn-danger:not(:disabled):not(.disabled):active {
  box-shadow: inset 0 0 0 1px #a81c29;
}

.btn-xs,
.btn-group-xs > .btn {
  font-size: 10.5px;
  padding: 3px 5px;
}

/* Bootstrap Collapse */
.panel {
  background: #F7F9F6 none;
  box-shadow: none;
}
.panel:hover {
  background: #E3F1DF none;
}

.panel-collapse {
  background: #FFF none;
}

.panel-title a {
  border-radius: 3px;
  display: block;
  padding: 10px 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel-title a::before {
  content: "\f0d7";
  display: inline-block;
  font-family: FontAwesome;
  width: 1em;
}
.panel-title a:hover {
  background: #e3f1df none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel-title a.collapsed::before {
  content: "\f0da";
  display: inline-block;
  font-family: FontAwesome;
  width: 1em;
}
.panel-title a.collapsed:hover {
  background: #e3f1df none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.panel-default {
  border: 1px solid #bfd9b9;
}
.panel-default > .panel-heading {
  background: transparent none;
  padding: 0;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bfd9b9;
}

.panel-group .panel + .panel {
  margin-top: 3px;
}

#changelanguage {
  min-height: 20px;
}
#changelanguage .dropdown-menu > li > a,
#changelanguage .dropdown-menu > li > span {
  padding: 5px 15px;
}
#changelanguage .navbar-text {
  margin: 0;
}
#changelanguage .navbar-text span {
  display: block;
  line-height: 20px;
}
#changelanguage .navbar-nav li a {
  color: #C00832;
  line-height: 20px;
  padding: 0.4em 15px;
}

.navbar-fixed-bottom {
  background: #FFF none;
  border-radius: 0;
  border-top: 1px solid #ADADAD;
  box-shadow: none;
}
.navbar-fixed-bottom fieldset {
  margin: 0;
  text-align: right;
}
.navbar-fixed-bottom .navbar-inner {
  min-height: 0;
  padding: 0.4em 0;
}
.navbar-fixed-bottom .nav > li {
  border-right: 1px solid #CCC;
}
.navbar-fixed-bottom .nav > li > a {
  font-weight: normal;
}
.navbar-fixed-bottom .nav > li:last-child {
  border-right: 0;
}
.navbar-fixed-bottom .nav > li.navbar-text {
  line-height: normal;
  padding: 0.4em 0.7em;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #EEE;
}
.tooltip.bottom .tooltip-inner {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #000;
  font-size: 120%;
  padding: 1em;
}

.separator {
  color: #bfbfbf;
  padding: 0 0.2em;
}

.close {
  filter: none;
  float: none;
  font-size: inherit;
  font-weight: normal;
  line-height: 1.5;
  opacity: inherit;
  position: inherit;
  right: auto;
  text-shadow: none;
  top: auto;
}
.close:hover {
  color: inherit;
  filter: inherit;
  font-size: inherit;
  opacity: inherit;
}

.radio label, .checkbox label {
  margin-left: 20px;
  padding-left: 0;
}
label.disabled {
  color: #CCC;
  cursor: not-allowed;
}

.radio input[type=radio] {
  margin-left: 0;
  position: relative;
}

.checkbox input[type=checkbox] {
  margin-left: 0;
  position: relative;
}

.closebtn {
  color: #000;
  cursor: pointer;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  margin-top: 4px;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}
.closebtn:focus, .closebtn:hover {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
}
.closebtn:active {
  box-shadow: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
}

.modal-header {
  border-bottom: 1px solid #f6009a;
}
.modal-header h3 {
  color: #000;
}

.modal-body {
  background-color: #FFF;
  overflow-y: auto;
}
.modal-body fieldset,
.modal-body ol {
  background-color: #FFF;
  border: 0;
  margin: 0;
  padding: 0;
}

.modal-content {
  background-color: #ffc3e9;
}
.modal-content fieldset {
  box-shadow: none;
}

.modal-footer {
  border-top: 1px solid #f6009a;
}

.btn-group label,
.btn-group select {
  font-size: 13px;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-right: 1px solid #de9f00;
}

.tooltip-inner {
  white-space: pre-wrap;
}

pre {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: block;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  padding: 0;
}

.pagination > li > a,
.pagination > li > span {
  font-weight: bold;
}

.tab-content {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  padding: 1em;
}

.tab-pane.active::after, .tab-pane.active::before {
  content: " ";
  display: table;
}
.tab-pane.active::after {
  clear: both;
}

.nav-tabs {
  border-bottom: 0;
  padding: 0.2em 1.4em 0 0;
}
.nav-tabs > li {
  margin-bottom: -2px;
}
.nav-tabs > li > a {
  background-color: #C00832;
  border: 2px solid #C00832;
  border-bottom: 0;
  border-radius: 0;
  color: #FFF;
  font-weight: bold;
  line-height: 1.3;
  margin-right: 0.4em;
  padding: 0.5em 1em;
}
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus, .nav-tabs > li > a:active {
  background-color: #C00832;
  border: 2px solid #C00832;
  border-bottom: 0;
  padding: 0.5em 1em;
  text-decoration: none;
}
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
  background-color: #FFF;
  border: 2px solid #FFF;
  color: #111;
  cursor: default;
  padding: 0.5em 1em;
}

.waiting {
  cursor: wait;
}

#jobpanel,
#jobstatus,
#jobfailed {
  display: none;
}

#jobstatus {
  margin: 0.4em;
}

#jobprogress {
  background: url("../img/progress.png") -300px 0 no-repeat;
  border: 1px solid #666;
  display: inline-block;
  height: 10px;
  width: 200px;
}

.progress_panel {
  border: 2px solid #EEE;
  border-radius: 5px;
  clear: both;
  font-size: 120%;
  margin: 1em 0;
  padding: 1em;
}

progress {
  width: 50%;
}

#selections {
  white-space: normal;
  width: 100%;
}
#selections input {
  margin: 0 2px;
  vertical-align: middle;
}
#selections span {
  background-color: #ecffeb;
  border-radius: 5px;
  display: inline-block;
  font-size: 75%;
  margin: 3px;
  padding: 3px;
  white-space: nowrap;
}
#selections span.selected {
  background-color: #d2fad0;
}

#changepasswordf input[type=text], #changepasswordf input[type=password] {
  font-family: "Courier New", Courier, monospace;
  font-size: 140%;
  padding: 0.3em;
}

.inline {
  display: inline;
}

.nowrap {
  white-space: nowrap;
}

.tag_editor {
  background: transparent url("../img/edit-tag.png") top left no-repeat;
  display: block;
  float: left;
  height: 16px;
  margin: 4px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 16px;
}
.tag_editor.upload {
  background: transparent none;
  font-size: 90%;
  height: unset;
  overflow: unset;
  text-indent: unset;
  width: unset;
}

.browse-controls {
  margin-left: 1.1em;
  margin-right: 0.5em;
  padding-bottom: 1em;
  padding-top: 1em;
}

#browse-return-to-results {
  background-color: #e6e6e6;
  border: 1px solid #f96889;
  border-bottom-width: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  padding: 0.5em;
  text-align: center;
}

.browse-button {
  background-color: transparent;
  border: 1px solid #f96889;
  display: block;
  overflow: hidden;
  padding: 0.4em 0.6em;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

a.browse-button {
  color: #C00832;
}
a.browse-button:hover {
  background: #fff;
}

span.browse-button {
  color: #999;
}
span.circ-hlt {
  color: #CC0000;
  font-weight: bold;
}
span.expired {
  color: #990000;
  font-style: italic;
}
span.name {
  font-style: italic;
  font-weight: bold;
}
span.required {
  color: #CC0000;
  font-style: italic;
  margin-left: 0.5em;
}
span.important {
  color: #EAC117;
  font-style: italic;
  margin-left: 0.5em;
}
span[class*=" label-"] {
  color: #FFF;
  display: inline;
  font-size: 75%;
  font-weight: normal;
  padding: 0.2em 0.6em 0.3em;
}

.result-biblio-itemtype {
  float: right;
  font-size: 85%;
  margin: 0.5em;
  padding: 0.5em;
  text-align: center;
}
.result-biblio-itemtype img {
  display: block;
  margin: auto;
  margin-bottom: 2px;
}

#browse-previous {
  border-bottom-width: 0;
}

#browse-next {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.loading-overlay {
  background-color: #FFFFFF;
  cursor: wait;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.loading-overlay div {
  background: transparent url("../img/loading.gif") top left no-repeat;
  font-size: 175%;
  font-weight: bold;
  height: 2em;
  left: 50%;
  margin: -1em 0 0 -2.5em;
  padding-left: 50px;
  position: absolute;
  top: 50%;
  width: 15em;
}

#merge_invoices {
  display: none;
  margin: 1em auto;
}

#merge_table tr.active td {
  background-color: #FFFFCC;
}

input.renew {
  margin-right: 1em;
}

.renewals-info {
  display: block;
  font-size: 0.8em;
  padding: 0.5em;
}

.date-select label {
  width: 40%;
}

#newonholdduedate {
  display: none;
}

#transport-types {
  padding-top: 0.5px;
}

#i18nMenu .navbar-text .currentlanguage {
  color: #000;
  font-weight: bold;
}
#i18nMenu a.currentlanguage:link, #i18nMenu a.currentlanguage:visited {
  font-weight: bold;
}
#i18nMenu a .sublanguage-selected {
  color: #000;
  font-weight: bold;
}

.onsite_checkout-select label, #circ_circulation_issue .onsite_checkout-select {
  font-size: inherit;
  font-weight: normal;
}

.onsite_checkout {
  color: #CC0000;
}

.onsite-checkout-only {
  background-color: rgba(255, 242, 206, 0.5);
  border: 1px solid #FFF2CE;
  border-radius: 4px;
}

.branchgriditem {
  background-color: #FFFFFF;
  border: 1px solid #bfd9b9;
  border-radius: 3px;
  display: table-cell;
  float: left;
  margin: 3px;
  padding: 0.3em;
}

.branchgridrow {
  display: table-row;
}

.branchselector {
  display: table;
}

.hq-author {
  font-weight: bold;
}

#cn_browser_table_wrapper > #cn_browser_table {
  margin: auto;
  width: 90%;
}

#new_rule {
  background-color: #F4F8F9;
  border: 2px solid #bfd9b9;
  border-radius: 5px;
  display: none;
  margin: 0.3em;
  padding: 0.3em;
}

.blocks {
  margin-bottom: 0.3em;
}

.remove_rule {
  font-size: 80%;
  padding-left: 0.7em;
}

.underline {
  text-decoration: underline;
}

.overline {
  text-decoration: overline;
}

.order-control {
  padding-right: 5px;
}

#borrower_message {
  margin-top: 10px;
}

.form-group {
  margin-bottom: 10px;
}
.form-group label {
  font-weight: bold;
}

.form-message {
  background-color: #FFF;
  border: 1px solid #A4BEDD;
  border-radius: 5px;
  margin: 1em;
  padding: 0.5em;
}

.modal-textarea {
  width: 98%;
}

#pat_member #patron_list_dialog {
  display: none;
}

#fixedlengthbuilderaction {
  border: 3px solid #e3f1df;
  left: 80%;
  padding: 5px;
  position: relative;
  top: -80px;
  width: 12%;
}

#interlibraryloans #dataPreviewLabel {
  margin: 0.3em 0;
}
#interlibraryloans .bg-info {
  overflow: auto;
  position: relative;
}
#interlibraryloans .format h4 {
  margin-bottom: 20px;
}
#interlibraryloans .format h5 {
  margin-top: 20px;
}
#interlibraryloans .format input {
  margin: 10px 0;
}
#interlibraryloans .format li {
  list-style: none;
}
#interlibraryloans #add-new-fields {
  margin: 1em;
}
#interlibraryloans #column-toggle,
#interlibraryloans #reset-toggle {
  font-weight: 700;
  line-height: 1.5em;
  margin: 15px 0;
}
#interlibraryloans #freeform-fields .custom-name {
  margin-right: 1em;
  text-align: right;
  width: 9em;
}
#interlibraryloans #freeform-fields .delete-new-field {
  margin-left: 1em;
}
#interlibraryloans #search-summary {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#interlibraryloans #generic_confirm_search_count {
  margin: 1em 0 1em 10em;
}
#interlibraryloans #generic_confirm_search {
  display: block;
  visibility: hidden;
  margin: 1em 0 1em 10em;
}
#interlibraryloans #partnerSearch .modal-dialog {
  width: 50vw;
}
#interlibraryloans #partnerSearch .modal-body {
  max-height: 70vh;
}

.ill-view-panel {
  margin-top: 15px;
}
.ill-view-panel .notesopac {
  display: inline-block;
}

#illfilter_dateplaced_start,
#illfilter_datemodified_start,
#illfilter_dateplaced_end,
#illfilter_datemodified_end {
  width: 80%;
}

#requestattributes {
  font-family: monospace;
  line-height: 1.3em;
}

#ill-requests {
  width: 100% !important;
}

.ar-title .biblio-title, .hq-title .biblio-title {
  font-weight: bold;
}

#ill-issue-title {
  margin: 20px 0 30px 0;
}

.ill_availability_sourcename {
  margin-top: 20px;
}

#stockrotation h3 {
  margin: 30px 0 10px 0;
}
#stockrotation .dialog {
  margin-bottom: 20px;
}
#stockrotation .dialog h3 {
  margin: 10px 0;
}
#stockrotation .highlight_stage {
  font-weight: bold;
}

#catalog_stockrotation .highlight_stage {
  font-weight: bold;
}

#stockrotation #rota_form textarea {
  width: 300px;
  height: 100px;
}
#stockrotation #rota_form #name {
  width: 300px;
}
#stockrotation #rota_form fieldset {
  width: auto;
}
#stockrotation #stage_form fieldset, #stockrotation #add_rota_item_form fieldset {
  width: auto;
}
#stockrotation .dialog.alert ul {
  margin: 20px 0;
}
#stockrotation .dialog.alert li {
  list-style-type: none;
}

#catalog_stockrotation .item_select_rota {
  vertical-align: middle;
}
#catalog_stockrotation h1 {
  margin-bottom: 20px;
}

#stockrotation td.actions, #catalog_stockrotation td.actions {
  vertical-align: middle;
}

#stockrotation .stage, #catalog_stockrotation .stage {
  display: inline-block;
  padding: 5px 7px;
  margin: 3px 0 3px 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

#restriction_form .type_input {
  text-transform: uppercase;
}

#stage_list_headings {
  font-weight: bold;
}
#stage_list_headings span {
  padding: 3px;
}

#manage_stages ul {
  padding-left: 0;
}
#manage_stages li {
  list-style: none;
  margin-bottom: 5px;
}
#manage_stages li span {
  padding: 6px 3px;
}
#manage_stages .stagename {
  width: 15em;
  display: inline-block;
}
#manage_stages .stageduration {
  width: 10em;
  display: inline-block;
}
#manage_stages .stageactions {
  display: inline-block;
}
#manage_stages li:nth-child(odd) {
  background-color: #F3F3F3;
}
#manage_stages .drag_handle {
  margin-right: 6px;
  cursor: move;
}
#manage_stages .drag_placeholder {
  height: 2em;
  border: 1px dotted #aaa;
}
#manage_stages h3 {
  display: inline-block;
}
#manage_stages #ajax_status {
  display: inline-block;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 5px;
  margin-left: 10px;
  background: #f3f3f3;
}
#manage_stages #manage_stages_help {
  margin: 20px 0;
}

#helper span {
  display: none;
}

#logged-in-info-full {
  display: none;
}

.loggedin-menu-label {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 1.42857143;
  padding: 4px 12px;
  white-space: nowrap;
}
.loggedin-menu-label span {
  color: #FFFFFF;
  font-weight: bold;
  left: 15px;
  top: 0;
}
.loggedin-menu-label.divider {
  padding: 0;
}

/* ==== MODULE LINKS - Start ==== */
.buttons-list {
  /* List containing the module links */
  padding: 0;
  max-width: 260px;
}
.buttons-list li {
  /* Standard attributes for the list elements */
  list-style-type: none;
  margin-bottom: 15px;
}
.buttons-list li a {
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 6px;
  color: #101010;
  display: block;
  font-size: 110%;
  font-weight: bold;
}
.buttons-list li a:hover {
  background-color: #C00832;
  color: white;
  text-decoration: none;
}

.about h2 {
  border-bottom: 1px solid #bfd9b9;
  padding: 0.5em 0.2em;
  margin: 0.5em 0;
}

.columns-2 {
  columns: 2 auto;
  column-gap: 2.5em;
}

.columns-3 {
  columns: 3 auto;
  column-gap: 2.5em;
}

.columns-4 {
  columns: 4 auto;
  column-gap: 2em;
}

/* ==== MODULE LINKS - End ==== */
#catalog-search-link {
  border-right: 1px solid #C00832;
  padding-right: 0.3em;
}

#catalog-search-dropdown {
  padding: 0;
}
#catalog-search-dropdown > a {
  border-left: 1px solid #a8072c;
  margin-right: 0.6em;
  padding: 0.4em 0.6em;
}
#catalog-search-dropdown > a:hover, #catalog-search-dropdown > a.catalog-search-dropdown-hover {
  background-color: #a8072c;
  border-left: 1px solid #f61f50;
}

#tools_holidays .radio label,
#tools_holidays .checkbox label {
  margin-left: 0;
}

/* Permissions */
#permissionstree {
  display: inline-block;
}
#permissionstree label {
  cursor: pointer;
}
#permissionstree label:hover {
  color: #C00832;
}

.main_permission {
  font-size: 110%;
  font-weight: bold;
}

.permissioncode {
  color: #666;
  font-style: italic;
}

.permission-highlight {
  background-color: #FFC !important;
}

.togglechildren_on,
.togglechildren_off {
  float: right;
}

.togglechildren_off,
.children {
  display: none;
}

.open .togglechildren_off {
  display: inline;
}
.open .togglechildren_on {
  display: none;
}

.parent {
  border: 1px solid #DDD;
  border-bottom-width: 0;
  padding: 5px;
}
.parent:last-child {
  border-bottom-width: 1px;
}
.parent:nth-child(odd) {
  background-color: #F8F8F8;
}

.superlibrarian-hint {
  color: #000;
  padding: 0.2em 0;
  text-indent: 2em;
}

.child-flags {
  background-color: #FFF;
  border: 1px solid #DDD;
  border-bottom-width: 0;
  margin: 0 5px 0 20px;
  padding: 5px;
}
.child-flags:first-child {
  margin-top: 10px;
}
.child-flags:last-child {
  border-bottom-width: 1px;
}
.child-flags:nth-child(odd) {
  background-color: #EEE;
}

#user-menu {
  position: absolute;
  right: 5px;
  top: 0;
}

div#makechart ol li {
  list-style: none;
}

div .suggestion_note {
  background: transparent none;
  border-style: dotted;
  border-width: 1px 0 0 0;
  font-size: 90%;
  padding: 2px 0 0 0;
}
div .suggestion_note i {
  color: #CCC;
}

.ac-library {
  background-color: #EEE !important;
  border-radius: 4px;
  color: #000;
  display: inline-block;
  font-size: 80%;
  padding: 1px 4px !important;
}

.ac-currentlibrary .ac-library {
  background-color: #E6FCB7 !important;
  font-weight: bold;
}

.currentlibrary {
  display: inline-block;
  padding: 2px 4px;
}

.availability .item_count {
  font-weight: bold;
  padding: 2px;
}
.availability .item_count::after {
  content: ")";
}
.availability .item_count::before {
  content: "(";
}
.availability .item_counts {
  font-weight: bold;
  white-space: nowrap;
}
.availability .results_available_count {
  font-weight: bold;
  margin-bottom: 0.5em;
}
.availability .results_checkedout {
  color: #900;
  margin: 0.3em 0;
}
.availability .results_unavailable {
  color: #555;
  font-style: italic;
  margin: 0.3em 0;
}
.availability .result_item_details {
  display: inline-block;
  white-space: nowrap;
}
.availability .result_item_details::before {
  content: "•";
}
.availability .item-date-due {
  display: block;
}

#camera, #output {
  border: 8px solid #EDF4F6;
  padding: 1em;
}

#photo {
  display: block;
  margin: auto;
}

#camera-error {
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
}
#camera-error div {
  padding: 0 0.5em;
}

#koha_version {
  float: right;
  margin: 0.5em;
}

.tab-pane.active::after {
  clear: both;
  content: "";
  display: block;
}

a.navbar-toggle {
  color: #fff;
}

#header .navbar-nav > li > a {
  overflow: hidden;
  color: #FFFFFF;
  text-shadow: unset;
}
#header .navbar-nav > li > a:hover, #header .navbar-nav > li > a:active, #header .navbar-nav > li > a:focus {
  background-color: transparent;
  text-decoration: underline 2px;
}
#header .dropdown-menu {
  background-color: rgb(53, 44, 46);
  font-size: 13px;
}
#header .dropdown-menu li a {
  color: #FFFFFF;
}
#header .dropdown-menu li a:hover, #header .dropdown-menu li a:focus {
  background: #C00832 none;
  text-decoration: none;
}

#logo {
  display: flex;
  align-items: center;
  padding: 0.2em 0.5em;
}
#logo img {
  height: 80%;
}

#toplevelmenu {
  background-color: transparent;
  box-shadow: none;
  position: relative;
  left: 0;
  top: 0;
}

#header_search {
  background-color: #C00832;
  border-radius: 0;
  border: 1px solid #C00832;
  display: flex;
  padding: 0 0.8em;
}
#header_search ul {
  padding: 0;
  margin: 0;
  border-radius: 0 16px 16px 0;
  display: flex;
  align-items: center;
  color: white;
  background-color: #C00832;
  z-index: 2;
  flex-grow: 1;
}
#header_search ul li {
  background: transparent;
  border: 0;
  border-radius: 0;
  border: 0;
  border-bottom-width: 0;
  padding-bottom: 0;
  list-style-type: none;
  margin: 0;
  white-space: nowrap;
}
#header_search ul li a {
  color: #FFF;
  padding: 0 0.25em;
  margin: 0 0.25em;
  border: 2px solid transparent;
}
#header_search form {
  display: flex;
  position: relative;
}
#header_search .form-title {
  padding: 0 16px 0 0;
  background-color: #C00832;
  border-radius: 0 16px 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  white-space: nowrap;
  flex-basis: 15%;
}
#header_search .form-title label {
  color: white;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
}
#header_search .form-content {
  background-color: white;
  margin-left: -16px;
  padding-left: 16px;
  border-radius: 0 16px 16px 0;
  white-space: nowrap;
  z-index: 1;
  display: flex;
  flex-grow: 1;
}
#header_search .form-content input[type=text],
#header_search .form-content select {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: unset;
  margin: 0;
}
#header_search .form-content input[type=text]:not(:first-child),
#header_search .form-content select:not(:first-child) {
  border-left: 1px solid #e0e0e0;
}
#header_search .form-content input + input {
  padding-right: 0.5em;
}
#header_search .form-control {
  font-size: 1em;
}
#header_search .form-extra-content {
  right: 60px;
  position: absolute;
  top: 31px;
  background-color: #f3f4f4;
  background-color: white;
  padding: 1em;
  border-radius: 0 0 8px 8px;
  border: 1px solid #C00832;
  border-top: 0 none;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1882352941);
  z-index: 1;
  display: none;
}
#header_search .form-extra-content a {
  color: #C00832;
}
#header_search .form-extra-content div {
  margin-bottom: 0.5em;
}
#header_search .form-extra-content .form-control {
  display: block;
  width: 100%;
}
#header_search .form-extra-content label {
  display: block;
  margin-bottom: 0;
}
#header_search .form-extra-content-toggle {
  background-color: transparent;
  opacity: 0.5;
  padding-right: 1em;
}
#header_search .form-extra-content-toggle:hover {
  opacity: 1;
}
#header_search input[type=submit],
#header_search button[type=submit] {
  height: 31px;
  background-color: #f6009a;
  color: white;
  border: 0;
  text-shadow: unset;
  border-radius: 0 16px 16px 0;
  margin-left: -16px;
  margin-right: 1em;
  padding-left: calc(16px + 0.7em);
  padding-right: 0.9em;
}
#header_search input[type=submit]:hover,
#header_search button[type=submit]:hover {
  background-color: #ff10a6;
}
#header_search input[type=button], #header_search button {
  height: 31px;
  border: 0;
  text-shadow: unset;
}
#header_search input[type=button]:active, #header_search button:active {
  box-shadow: unset;
}
#header_search .tab-content {
  background-color: transparent;
  padding: 0;
}
#header_search .tab-content:first-of-type {
  flex-basis: 50%;
}
#header_search .nav-tabs > li > a {
  background-color: #C00832;
  border: 2px solid #C00832;
  border-radius: 0;
  color: #FFF;
  font-weight: normal;
  line-height: 1.3;
  margin: 0 0.25em;
  padding: 0 0.25em;
}
#header_search .nav-tabs > li > a:hover, #header_search .nav-tabs > li > a:focus, #header_search .nav-tabs > li > a:active {
  background-color: #C00832;
  border: 2px solid #C00832;
  border-bottom-color: #f6009a;
  border-bottom: 0;
  padding: 0 0.25em;
  text-decoration: none;
}
#header_search .nav-tabs > li.active a {
  cursor: default;
  padding: 0 0.25em;
  border-bottom: 2px solid #FFF;
}
#logged-in-menu:hover, #logged-in-menu:active, #logged-in-menu:focus {
  text-decoration: none;
}

#lastborrower-window {
  display: none;
  background-color: #C00832;
  box-shadow: 1px 1px 1px 0 #999;
  color: #FFFFFF;
  padding: 0.2em;
  border-radius: 5px 5px 5px 5px;
}
#lastborrower-window > * {
  padding: 0 0.4em;
}

#lastborrower-remove {
  cursor: pointer;
  border-left: 1px solid #fff;
}

#lastborrowerlink {
  color: #FFFFFF;
}

@media (max-width: 768px) {
  #header_search .form-title {
    display: none;
  }
}
@media (max-width: 992px) {
  #header_search .residentsearch {
    flex-grow: 1;
  }
  #header_search .tab-title {
    display: none;
  }
  #header_search > ul {
    flex-grow: 0;
  }
}
#toolbar,
.btn-toolbar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: #f3F4F4;
  border: 0;
  margin-bottom: 5px;
  padding: 5px;
}
#toolbar .dropdown-menu,
.btn-toolbar .dropdown-menu {
  border-top-width: 1px;
  font-size: 13px;
}
#toolbar.floating,
.btn-toolbar.floating {
  border-radius: 0;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  margin-top: 0;
  z-index: 100;
}
#toolbar .btn.btn-default,
.btn-toolbar .btn.btn-default {
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 6px 12px;
  box-shadow: none;
  border-radius: 4px;
  user-select: none;
  font-size: 100%;
  background-color: transparent;
  border: 0;
  display: inline-block;
  color: #696969;
}
#toolbar .btn.btn-default:hover, #toolbar .btn.btn-default:focus,
.btn-toolbar .btn.btn-default:hover,
.btn-toolbar .btn.btn-default:focus {
  text-decoration: none;
  background-color: #DADADA;
  color: #696969;
  padding: 6px 12px;
}
#toolbar .btn.btn-default:active,
.btn-toolbar .btn.btn-default:active {
  border: 0;
}
#toolbar .btn.btn-default.addtocart,
.btn-toolbar .btn.btn-default.addtocart {
  display: block;
}
#toolbar .btn.btn-default.addtocart.incart,
.btn-toolbar .btn.btn-default.addtocart.incart {
  display: none;
}
#toolbar .btn.btn-default.cartRemove,
.btn-toolbar .btn.btn-default.cartRemove {
  padding: 6px 12px;
  font-size: 12px;
  display: none;
}
#toolbar .btn.btn-default.cartRemove.incart,
.btn-toolbar .btn.btn-default.cartRemove.incart {
  display: block;
}
#toolbar .btn.btn-link,
.btn-toolbar .btn.btn-link {
  font-size: 100%;
}
#toolbar.selections-toolbar a,
.btn-toolbar.selections-toolbar a {
  display: inline-block;
  padding: 0 0.5em;
}

fieldset {
  margin-bottom: 1rem;
  padding: 1rem;
}
fieldset:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action) {
  background-color: white;
}
fieldset legend {
  border-bottom: 0 none;
  color: #696969;
  float: left;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
fieldset legend ~ * {
  clear: left;
}
fieldset fieldset {
  box-shadow: none;
  padding: 0;
}
fieldset.rows {
  clear: left;
  float: left;
  margin: 0.9em 0 0;
  padding: 1rem;
  width: 100%;
}
fieldset.rows fieldset {
  background-color: transparent;
  margin: 1em;
  padding: 0.3em;
}
fieldset.rows fieldset.action {
  padding: 1em;
}
fieldset.rows.inputnote {
  clear: left;
  float: left;
  margin: 1em 0 0 9em;
}
fieldset.rows.left label {
  text-align: left;
  width: 8em;
}
fieldset.rows.left li {
  padding-bottom: 0.4em;
}
fieldset.rows.left span label {
  text-align: left;
  width: 8em;
}
fieldset.rows.unselected {
  background-color: #FFFFFF;
  border: 0;
  border-width: 0;
}
fieldset.rows caption {
  font-size: 120%;
}
fieldset.rows div.hint {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  margin-left: 11rem;
}
fieldset.rows label {
  float: left;
  font-weight: bold;
  margin-right: 1rem;
  text-align: right;
  width: 10rem;
}
fieldset.rows label.error {
  float: none;
  margin-left: 1rem;
  width: auto;
}
fieldset.rows label.inline {
  display: inline;
  float: none;
  margin-left: 1rem;
}
fieldset.rows label.radio {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 0;
  width: auto;
}
fieldset.rows label.yesno {
  float: none;
  width: auto;
}
fieldset.rows li {
  clear: left;
  float: left;
  list-style-type: none;
  width: 100%;
}
fieldset.rows li[aria-disabled=true] {
  color: #999;
}
fieldset.rows li.radio {
  margin: 0;
  padding-left: 7.5em;
  width: auto;
}
fieldset.rows li.radio input + label {
  margin-left: 0;
  padding-left: 0;
}
fieldset.rows li.radio label {
  float: none;
  margin: 0 0 0 1em;
  width: auto;
}
fieldset.rows li input + label {
  margin-left: 0;
  padding-left: 0;
}
fieldset.rows li fieldset {
  margin: 0;
  padding: 0;
}
fieldset.rows li fieldset input[type=text] {
  max-width: 70%;
}
fieldset.rows li fieldset label {
  width: 6em;
}
fieldset.rows li fieldset legend {
  font-size: 1.55rem;
  margin: 0 0 0 1em;
}
fieldset.rows li fieldset li.radio {
  padding-left: 0;
}
fieldset.rows ol {
  list-style-type: none;
  padding: 1em 1em 0;
}
fieldset.rows ol.radio label {
  float: none;
  margin-left: 20px;
  margin-right: 30px;
  padding-left: 0;
  vertical-align: middle;
  width: auto;
}
fieldset.rows ol.radio label.radio {
  float: left;
  margin-right: 1em;
  margin-top: 0;
  width: 10em;
}
fieldset.rows ol.radio input[type=checkbox], fieldset.rows ol.radio input[type=radio] {
  margin-left: -20px;
}
fieldset.rows ol table {
  display: inline-block;
  font-size: 105%;
  margin: 0;
}
fieldset.rows p {
  margin: 1em 0 1em 1em;
}
fieldset.rows span.label {
  float: left;
  font-weight: bold;
  margin-right: 1rem;
  text-align: right;
  width: 10rem;
}
fieldset.rows span.hint {
  font-size: 1.25rem;
}
fieldset.rows table {
  clear: both;
  font-size: 1.25rem;
  margin: 1em 0 1em 1em;
}
fieldset.rows table.dataTable {
  margin: 0;
}
fieldset.rows table.mceListBox {
  margin: 0;
}
fieldset.rows td label {
  float: none;
  font-weight: normal;
  width: auto;
}
fieldset.rows .inputnote {
  clear: left;
  float: left;
  margin: 1em 0 0 11em;
}
fieldset.rows + h3 {
  clear: both;
  padding-top: 0.5em;
}
fieldset.rows .dataTables_wrapper label {
  font-weight: 400;
  width: unset;
}
fieldset.rows .dropdown-menu li {
  padding-bottom: 0;
}
fieldset.rows .toptabs {
  clear: both;
  margin: 1em;
}

@media (max-width: 767px) {
  .header-menu-link {
    display: inline-block;
  }
  #catalog-search-link {
    display: none;
    padding: 0;
  }
  #cartmenulink {
    background: transparent none;
    padding-left: 1.5em;
  }
  #changelanguage li {
    float: left;
  }
  #changelanguage li li {
    float: none;
  }
  #changelanguage .open .dropdown-menu {
    background-color: #352C2E;
    border: 1px solid #ccc;
    position: absolute;
  }
  #header {
    background-color: #352C2E;
    margin-bottom: 1em;
  }
  #header a {
    font-weight: normal;
    padding-left: 1.5em;
  }
  #header a:hover {
    text-decoration: underline;
    color: #FFF;
    background-color: #352C2E;
    background-image: none;
  }
  #header ul {
    border: 0;
    box-shadow: unset;
    float: none;
    left: auto;
    position: initial;
    right: auto;
  }
  #header ul.dropdown-menu {
    display: block;
  }
  #header .dropdown-toggle {
    display: none;
  }
  .gradient {
    align-items: center;
    flex-direction: column;
  }
  #marcPreview {
    margin: 0;
    width: auto;
  }
  .navbar-fixed-bottom .nav > li {
    border-right: 0;
  }
  #user-menu .open .dropdown-menu {
    background-color: #352c35;
    position: absolute;
  }
  #user-menu li {
    float: left;
  }
  #user-menu li li {
    float: none;
  }
}
@media (max-width: 768px) {
  .navbar-nav li a {
    padding: 0.4em 0.6em;
  }
}
@media only screen and (max-width: 768px) {
  .browse-button {
    display: inline-block;
    width: 50%;
  }
  #browse-previous {
    border-bottom-left-radius: 5px;
    border-bottom-width: 1px;
    border-right-width: 0;
  }
  #browse-next {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
  }
}
@media (min-width: 800px) {
  #helper i {
    display: none;
  }
  #helper span {
    display: inline;
  }
  #logged-in-info-full {
    display: inline;
  }
  #logged-in-info-brief {
    display: none;
  }
  .loggedin-menu-label {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .browse-button {
    display: inline-block;
    width: 50%;
  }
  #browse-previous {
    border-bottom-left-radius: 5px;
    border-bottom-width: 1px;
    border-right-width: 0;
  }
  #browse-next {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
  }
}
@media print {
  body.modalprinter * {
    visibility: hidden;
  }
  body.modalprinter .modal-dialog.focused {
    position: absolute;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
  }
  body.modalprinter .modal-dialog.focused .modal-content {
    border-width: 0;
  }
  body.modalprinter .modal-dialog.focused .modal-content .modal-header .modal-title,
  body.modalprinter .modal-dialog.focused .modal-content .modal-body,
  body.modalprinter .modal-dialog.focused .modal-content .modal-body * {
    visibility: visible;
  }
  body.modalprinter .modal-dialog.focused .modal-content .modal-header,
  body.modalprinter .modal-dialog.focused .modal-content .modal-body {
    padding: 0;
  }
  body.modalprinter .modal-dialog.focused .modal-content .modal-header .modal-title {
    margin-bottom: 20px;
  }
}